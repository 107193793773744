export const LCSpecialitiesArray = [
  { label: 'Any Speciality', value: 'all', description: '' },
  {
    label: 'Aggro',
    value: 'Aggro',
    description:
      'In focused encounters, slots with higher Aggro values are more likely to be targeted by enemies.'
  },
  {
    label: 'Ammo',
    value: 'Ammo',
    description: 'Spent by certain skills. Some attacks cancel if lacking Ammo.'
  },
  {
    label: 'Barrier',
    value: 'Barrier',
    description: '-'
  },
  {
    label: 'Bind',
    value: 'Bind',
    description: 'Speed decreases by the effect’s Potency for one turn.'
  },
  {
    label: 'Bleed',
    value: 'Bleed',
    description:
      'When attacking, take fixed damage equal to (Potency)% of current HP, then halve it.'
  },
  {
    label: 'Burn',
    value: 'Burn',
    description:
      'At the end of the turn, take fixed damage by the effect’s Potency, then halve it.'
  },
  {
    label: 'Charge',
    value: 'Charge',
    description:
      'Resource used by certain skills for additional effects. Its Count can go up to 20. Turn End: Lower Count by 1.'
  },
  {
    label: 'Discard',
    value: 'Discard',
    description: '-'
  },
  {
    label: 'Fragile',
    value: 'Fragile',
    description:
      'Take more damage from skills based on the effects Potency for one turn. (Max. 10)'
  },
  {
    label: 'Haste',
    value: 'Haste',
    description: 'Speed increases by the effect’s Potency for one turn.'
  },
  {
    label: 'Nails',
    value: 'Nails',
    description:
      'Turn Start: Apply 1 Bleed to self, and increase Bleed count by this effects Count. Turn End: Reduce Count by 1.'
  },
  {
    label: 'Paralyze',
    value: 'Paralyze',
    description: 'Fix the Power of X Coin(s) to 0 for one turn.'
  },
  {
    label: 'Poise',
    value: 'Poise',
    description:
      'On hit, gain a Potency-based chance to deal critical damage, reducing the Count by 1 if successful. At the end of the turn, reduce the Count by 1.'
  },
  {
    label: 'Rupture',
    value: 'Rupture',
    description:
      'When hit by an attack, take fixed damage by the effect’s Potency. Then, reduce its Count by 1.'
  },
  {
    label: 'Sinking',
    value: 'Sinking',
    description:
      'When hit by an attack, take fixed SP damage by the effect’s Potency. (Applies as Gloom-affinity damage to Abnormalities) Then, reduce its Count by 1.'
  },
  {
    label: 'Tremor',
    value: 'Tremor',
    description:
      'When attacked by skills that burst Tremor, raise the Stagger threshold by the effect’s Potency. At the end of the turn, reduce the Count by 1.'
  }
];
